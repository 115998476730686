import React, {useContext, useState} from "react";
import {Form, Input, notification} from 'antd';
import type {FormInstance} from 'antd/es/form';
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "@/router";
import {useAccountState} from "@/store";
import {contactApi} from "@/services";
import clsx from "clsx";
import {AppContext} from "@/hooks";
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination ,Autoplay } from 'swiper/modules';

const {TextArea} = Input;

export default function Home() {
    return (
        <div className="text-center">
            <HomeImg/>
            <News/>
            <About/>
            <Contact/>
        </div>
    )
}

const HomeImg = () => {
    const navigate = useNavigate();
    const account = useAccountState();
    const ctx = useContext(AppContext)

    const toProductList = () => {
        if (account.jwt === null) {
            ctx.login(RoutePaths.PRODUCT);
        } else {
            navigate(RoutePaths.PRODUCT);
        }
    }
    return (
        <div style={{height: "44rem"}} >

            <Swiper pagination={true} loop={true} clickable={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper">
                <SwiperSlide>
                    <div className="mod:h-72 homebg">
                        <div className=" w-full h-auto relative">
                            <div className=" absolute text-white w-96 text-left top-64 right-80 inline-block mod:left-5">
                                <div className="text-5xl">
                                    What is Kasai GO?
                                </div>
                                <div className="mt-7 text-xs space-y-2">
                                    <div>
                                        KasaiGOは、加西市の新しい応援団です。NFTやDAOといった最新のデジタル技術を駆使して、
                                        加西市の魅力を一緒に盛り上げ、新しい未来を築きましょう。加西市×Web3での挑戦、参加しませんか？
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <button className="must-login-bg text-white py-2 px-4 rounded-md text-lg"
                                            onClick={() => toProductList()}>GET NFT NOW !
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="mod:h-72 homebg1">
                        <div className=" w-full h-auto relative">
                            <div className=" absolute text-white w-96 text-left top-64 right-80 inline-block mod:left-5">
                                <div className="text-5xl text-black">
                                    What is
                                    Kasai GO?
                                </div>
                                <div className="mt-7 text-xs space-y-2">
                                    <div style={{color : "black"}}>
                                        KasaiGOは、加西市の新しい応援団です。NFTやDAOといった最新のデジタル技術を駆使して、
                                        加西市の魅力を一緒に盛り上げ、新しい未来を築きましょう。加西市×Web3での挑戦、参加しませんか？
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <button className="must-login-bg text-white py-2 px-4 rounded-md text-lg"
                                            onClick={() => toProductList()}>GET NFT NOW !
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};
const News = () => {
        const array = [1, 2, 3, 4, 5, 6];
        return (
            <div className="w-3/5 m-auto py-20 text-black mod:w-full mod:p-5" id="homepage-news">
                <div className="w-full">
                    {/* <Image src="/news.png" width={150} height={200} alt={""} className="m-auto"></Image> */}
                    <span className=" text-3xl">News</span>
                </div>
                <div>
                    <div className="flex justify-left space-x-4 my-6 ml-72 text-base font-medium cursor-pointer"
                         onClick={() => window.open("https://discord.com/channels/1130687675440054343/1204032842322022400/1204200052810190908", "_blank")}>
                        <div>2024.02.06</div>
                        <div>3/23(土)運行予定のキハ40のヘッドマーク投票受付中！</div>
                    </div>
                    <div className="flex justify-left space-x-4 my-6 ml-72 text-base font-medium cursor-pointer"
                         onClick={() => window.open("https://item.rakuten.co.jp/f282201-kasai/56981433/", "_blank")}>
                        <div>2024.02.05</div>
                        <div>3/23(土)北条鉄道キハ40貸切列車を運行予定！</div>
                    </div>
                    <div className="flex justify-left space-x-4 my-6 ml-72 text-base font-medium cursor-pointer"
                         onClick={() => window.open("https://prtimes.jp/main/html/rd/p/000000001.000132193.html", "_blank")}>
                        <div>2023.11.17</div>
                        <div>KasaiGO リリース開始！</div>
                    </div>
                </div>
            </div>

        );
    }
;
const About = () => {
        return (
            <div style={{background: "#cde3e9"}}>
                <div className="w-3/5 m-auto py-20 mod:w-full mod:p-5">
                    <div className="w-full">
                        {/* <Image src="/about.png" width={400} height={200} alt={""} className="m-auto"></Image> */}
                        <span className=" text-3xl">About Digitalme</span>
                    </div>
                    <div className=" text-left py-5">
                        <div className="title text-lg">
                        <span style={{borderBottom: "2px dashed"}}>
                            運営会社 Digitalmeについて
                        </span>
                        </div>
                        <div className="desc text-lg py-5">
                            Digitalmeとは、地方自治体と協力をして、地方創生に関する課題を解決する、いわば地方自治体応援団コミュニティを支援していくことをやっている企業です。
                            分散型のブロックチェーン技術を使って、
                            個人が個人データを管理し、個人データの価値を、個人が享受できる世界を創ることを目標に掲げています。
                            <br></br><br></br>
                            弊社の地方創生のプロジェクトに興味のある方は下記CONTACTまでお問い合わせください!
                        </div>

                        <div className="title text-lg py-3">
                        <span style={{borderBottom: "2px dashed"}}>
                            運営会社紹介
                        </span>
                        </div>

                        <div className="desc text-lg">
                            <div className="py-2">
                                <span className="intro inline-block">会社名</span>
                                <a target="_blank" href="https://digitalme.jp/"
                                   className="content inline-block ml-40 mod:ml-5 text-black">株式会社 デジタル・ミー</a>
                            </div>
                            <div className="py-2">
                                <div className="intro inline-block">代表者</div>
                                <div className="content inline-block ml-40 mod:ml-5">福羽 泰紀</div>
                            </div>
                            <div className="py-2">
                                <span className="">所在地</span>
                                <span className="ml-40 mod:ml-5">東京都千代田区大手町1丁目6番1号大手町ビル6F</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
;

const Contact: React.FC = () => {
        const formRef = React.useRef<FormInstance>(null);
        const account = useAccountState();

        const [loadingFlg, setLoadingFlg] = useState<boolean>(false);

        const onFinish = async (values: any) => {
            setLoadingFlg(true);
            const headers = {
                'content-type': 'application/json',
                Auth: "Bearer " + account.jwt,
            };
            let res = await contactApi(
                values, headers
            );
            setLoadingFlg(prevState => prevState = false);
            notification.success({
                message: "send successfully",
            });
            formRef.current?.resetFields();
            return;
        };

        return (
            <div className="w-3/5 m-auto py-20 mod:w-full mod:p-5" id="homepage-contact">
                <div className="w-full">
                    {/* <Image src="/contact.png" width={250} height={200} alt={""} className="m-auto"></Image> */}
                    <span className=" text-3xl">Contact</span>
                </div>

                <Form
                    layout={'vertical'}
                    ref={formRef}
                    name="control-ref"
                    size={"large"}
                    onFinish={onFinish}
                    style={{maxWidth: 600, margin: "0 auto"}}
                >
                    <Form.Item name="name" label="Name" rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="email" label="E-mail" rules={[{
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                        {
                            required: true,
                            message: 'Please input your E-mail!',
                        },]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item name="message" label="内容" rules={[{required: true, message: "内容 is required!"}]}>
                        <TextArea rows={4}/>
                    </Form.Item>

                    <Form.Item className='text-algin'>
                        <button className={clsx("bg-cyan-700 text-white py-2 px-4 rounded-lg font-bold",
                            loadingFlg && "btn-disabled")}>送 信
                        </button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
;