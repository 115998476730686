
interface ChildProps {
    onUpdate: () => void;
}

function MemberShipNFT({ onUpdate }: ChildProps) {
    const handleButtonClick = () => {
        // 子组件通过调用回调函数 onUpdate 来更新父组件的值
        onUpdate();
    };
    return (
        <div className="full-screen-overlay relative bg-45AAC3">
            <div className="absolute top-1/4 left-1/4 text-left text-white space-y-10 w-6/12 mod:left-0 mod:px-5 mod:top-20 mod:w-full">
                <div className="text-right flex justify-end ">
                    <img className="cursor-pointer" onClick={handleButtonClick} src={"./b-close.png"} width={30}/>
                </div>
                <div>
                    <div className="text-2xl mod:mt-48">メンバーシップNFT</div>
                    <div className="mod:mt-5">
                        メンバーシップNFTは、KasaiGOの公式メンバーの証明です。これがあれば、
                        KasaiGOのディスコード内で、DAOのチャンネルで投票もできてしまいます！
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MemberShipNFT;
