import React, {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {getProductDetail, postBuyProduct} from "@/services";
import {useAccountState} from "@/store";
import {RoutePaths} from "@/router";

export default function Detail() {
    const [params] = useSearchParams();
    let id = params.getAll("pid");
    const account = useAccountState();
    const [data, setData] = useState<any>();
    const navigate = useNavigate();
    const [imgSrc, setImgSrc] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [desc, setDesc] = useState<string>("");

    const getProductData = async () => {
        const token = account?.jwt;
        if (token) {
            let headers = {
                'Auth': 'Bearer ' + token,
                'content-type': 'application/json',
            };
            const data = await getProductDetail(Number(id), headers);
            if (data) {
                const jsonData = data;
                setData(jsonData);
                setName(jsonData?.collectioninfos[0].CollectionName);
                setDesc(jsonData?.collectioninfos[0].CollectionDetails);
                setImgSrc(jsonData?.collectioninfos[0].CollectionImageUrl);
            }
        }
    }

    const buy = async () => {
        let token = account?.jwt;
        if (token) {
            let headers = {
                'Auth': 'Bearer ' + token,
                'content-type': 'application/json',
            };
            const data = await postBuyProduct({"productId": id.toString()}, headers);
            if (data) {
                navigate(RoutePaths.NFT);
            }
        }
    }

    useEffect(() => {
        getProductData();
    }, [])
    return (
        <div className="container w-4/5 m-auto p-5 mod:w-full mod:p-0">
            <div className="text-left text-4xl my-5 mod:ml-5">
                Get NFTS
            </div>

            <div className="flex bg-E3F1F5 p-10 mb-10 mod:inline-block mod:p-5">
                <div className="w-2/5 mod:w-full">
                    <div className="h-96 rounded-lg flex items-center justify-center">
                        <div className="flex items-center justify-center h-full w-full">
                            <img src={imgSrc} alt={""} className="details-img-css object-contain"/>
                        </div>
                    </div>

                </div>
                <div className="w-3/5 mod:w-full">
                    <div className="text-left ml-10 space-y-5 mod:ml-0 mod:mt-5">
                        <div className="text-2xl mod:text-4xl">
                            <span>{name}</span>
                            <span className="ml-5 text-sm bg-45C3A7 p-1 rounded text-white">Collection NFT </span>
                        </div>
                        <div className="text-lg " dangerouslySetInnerHTML={{__html: desc}}></div>
                        <div className="text-lg">
                            <button className="bg-45AAC3 text-white py-2 px-4 rounded-lg" onClick={() => buy()}>BUY
                                (MINT)
                            </button>
                        </div>
                        <div className="text-sm">
                            ※会員登録をしてMembership NFTを獲得すると、Collection NFTを購入することができます。
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
