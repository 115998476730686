import React from "react";

const FooterForMobile = () => {
    return (
        <footer>
            <div className="mx-5">
                <div className="grid-container" style={{borderTop: "1px solid"}}>
                    <div className="grid-item">
                        <img src={"/logo.png"}/>
                    </div>
                    <div className="grid-item grid-item-right">
                        <div className='flex space-x-4 justify-end'>
                            <img className="cursor-pointer"
                                 onClick={() => window.open("http://twitter.com/KasaiGo_Web3", "_blank")}
                                 src="/x-twitter.svg"
                                 width={20} height={20} alt={''}/>
                            <img className="cursor-pointer"
                                 onClick={() => window.open("https://discord.gg/5BpwgwD5Dx", "_blank")} src="/discord.svg"
                                 width={20} height={20} alt={''}/>
                            <img className="cursor-pointer"
                                 onClick={() => window.open("https://instagram.com/kasaigo_web3", "_blank")}
                                 src="/instagram.svg" width={20} height={20} alt={''}/>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="grid-item">
                        <span>© 2023 Kasai Go</span>
                    </div>
                    <div className="grid-item grid-item-right text-xs">
                        <span className="cursor-pointer"
                            onClick={() => window.open(window.location.href + "特定商取引法に基づく表記.pdf", "_blank")}>特定商取引法に基づく表記</span>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default FooterForMobile;