import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from "../App";
import Home from "../pages/Home/page";
import PRODUCT from "../pages/Product/list";
import PRODUCT_DETAIL from "../pages/Product/detail";
import NFT from "../pages/Nft/list";
import NFT_DETAIL from "../pages/Nft/detail";

export enum RoutePaths {
    HOME = "/",
    PRODUCT = "/product",
    PRODUCT_DETAIL = "/productDetail",
    NFT = "/nft",
    NFT_DETAIL = "/nftDetail",
}

const renderRouter = () => {
    return (<BrowserRouter key={window.location.pathname}>
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route index element={<Home/>}></Route>
                    <Route path={RoutePaths.PRODUCT} element={<PRODUCT/>}></Route>
                    <Route path={RoutePaths.PRODUCT_DETAIL} element={<PRODUCT_DETAIL/>}></Route>
                    <Route path={RoutePaths.NFT} element={<NFT/>}></Route>
                    <Route path={RoutePaths.NFT_DETAIL} element={<NFT_DETAIL/>}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default renderRouter