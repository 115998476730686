import {  dappLink, getRecikaProviderConfig, NetWorks, } from "@/constants";
import { message, notification } from "antd";
import { BigNumber, Contract, ethers, PopulatedTransaction, providers } from "ethers";


declare let pop: any;
export const nullAddress = "0x0000000000000000000000000000000000000000";
export const nullHash =
  "0000000000000000000000000000000000000000000000000000000000000000";
export const maxUint256Hex =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
export const hexPaddingForAddress = "000000000000000000000000";

declare let window: any;

export const checkMetamask = () => {
  if (typeof window.ethereum === "undefined") {
    window.open(dappLink);
    message.warning("Metamask isn't installed!");
    return false;
  }
  return true;
};

export const getSigner = async (
  network: string
): Promise<ethers.providers.JsonRpcSigner> => {
  let porte = await getRecikaProviderConfig(network);
  const provider = new ethers.providers.Web3Provider(porte);
  return provider.getSigner();
};

export const checkChain = (chainId: number) => {
  const legalNetwork = Object.keys(NetWorks).find(
    (k) => parseInt(NetWorks[k]) === chainId
  );
  return legalNetwork;
};