import React, {useEffect, useState} from 'react'
import CollectionNFT from "@/components/Popup/CollectionNFT";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "@/router";
import {getProductList} from "@/services";
import {useAccountState} from "@/store";

export default function List() {
    const account = useAccountState();
    const [data, setData] = useState([]);
    const [popupFlg, setPopupFlg] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleChildUpdate = () => {
        setPopupFlg(false);
    };

    const toDetails = (pid: number) => {
        navigate(RoutePaths.PRODUCT_DETAIL.concat("?pid=" + pid));
    }

    const getProductListData = async () => {
        const token = account?.jwt;
        if (token) {
            let headers = {
                'Auth': 'Bearer ' + token,
                'content-type': 'application/json',
            };
            const data = await getProductList(headers);
            if (data){
                setData(data);
            }
        }
    }

    useEffect(() => {
        getProductListData();
    },[])

    return (
        <div className="container w-4/5 m-auto p-5 mod:w-full mod:p-0">
            <div className="text-left text-4xl my-5 mod:ml-5">
                Get NFTS
            </div>
            <div className="p-10 my-5 mod:p-5" style={{background: "#E3F1F5"}}>
                <div className="flex justify-between items-center my-5">
                    <div className="text-left text-3xl mod:text-2xl">
                        Kasai Go NFTs
                    </div>
                    <div>
                        <span className="p-3 text-emerald-500 bg-white font-bold rounded cursor-pointer"
                              onClick={() => setPopupFlg(true)}> About Collection NFT</span>
                    </div>
                </div>
                <div className="overflow-x-auto max-w-full">
                    <div className="whitespace-nowrap text-left space-x-20 mod:flex mod:flex-wrap mod:space-x-0">
                        {data.map((item: any, i: number) => (
                            <div key={i} className="w-1/5 text-center inline-block mod:w-1/2 mod:mb-4 cursor-pointer"
                                 onClick={() => toDetails(Number(item.ProductId))}>
                                <div
                                    className="w-full h-80 bg-white rounded-t-lg flex items-center justify-center mod:w-11/12 mod:h-64">
                                    <div className="flex items-center justify-center h-full w-full">
                                        <img src={item.ProductImageUrl} alt={item.ProductName} className="h-5/6 w-5/6 object-contain" />
                                    </div>
                                </div>
                                <div className="text-white text-left p-1 rounded-b-lg mod:w-11/12"
                                     style={{background: '#45C3A7'}}>
                                    Collection NFT
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {popupFlg && <CollectionNFT onUpdate={handleChildUpdate}></CollectionNFT>}
        </div>
    )
}
