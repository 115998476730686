import { IAccountState, initialAccountState } from "@/store/account"
import { createContext } from "react"


export type IAppContext = {
  login: CallableFunction
  logout: CallableFunction
  util: {
    isMobile: CallableFunction
    numberFormat: CallableFunction
  }
  width: number
  state: {
    account: IAccountState,
    dispatch: CallableFunction
  }
}

export const AppContext = createContext<IAppContext>({
    login: () => {},
    logout: () => {},
    util: {
      isMobile: () => {},
      numberFormat: ()=> {},
    },
    width: 0,
    state: {
      account: initialAccountState,
      dispatch: () => {
      },
    }
  }
)
