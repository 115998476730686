import React, {useContext} from 'react';
import {Utils} from "../../utils";
import {Link, useNavigate} from "react-router-dom";
import {RoutePaths} from "@/router";
import {useTranslation} from "react-i18next";
import {useAccountState, useAppDispatch} from "@/store";
import {Actions} from "@/store/account";
import {AppContext} from "@/hooks";
import copy from "clipboard-copy";
import {notification} from "antd";

export enum LocalLanguage {
    JA = "ja",
    EN = "en",
}

const Header = () => {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const account = useAccountState();
    const ctx = useContext(AppContext)
    const dispatch = useAppDispatch();
    const toPage = (pageName: string) => {
        if (window.location.pathname !== "/") {
            navigate(RoutePaths.HOME);
            setTimeout(() => {
                Utils.scrollToDiv(pageName)
            }, 400)
        } else {
            Utils.scrollToDiv(pageName)
        }
    }

    const onchangeLanguage = () => {
        let language: string = i18n.language == LocalLanguage.JA ? LocalLanguage.EN : LocalLanguage.JA;
        i18n.changeLanguage(language);
    };

    const LoginBtnText = account.jwt === null ? t("login") : t("logout");
    const LoginBtnClick = () => {
        if (account.jwt === null) {
            ctx.login(RoutePaths.HOME);
        } else {
            dispatch(Actions.logout());
            navigate(RoutePaths.HOME);
        }
    };

    const toMyNfts = async () => {
        if (account.jwt === null) {
            ctx.login(RoutePaths.NFT);
        } else {
            navigate(RoutePaths.NFT);
        }
    }

    const toGetNfts = async () => {
        if (account.jwt === null) {
            ctx.login(RoutePaths.PRODUCT);
        } else {
            navigate(RoutePaths.PRODUCT);
        }
    }

    const copyAddress = async (e: any) => {
        e.stopPropagation();
        copy(account.recikaEthAddress!).then(() => {
            notification.success({message: t("copySucceed")});
        });
    };

    return (
        <header className="bg-white">
            <div className="container mx-auto flex items-center justify-between w-4/5">
                <div className="flex items-center">
                    <div className="text-lg font-bold">
                        <Link to={"/"}>
                            <img className='object-none' src={"/logo.png"} alt={''}/>
                        </Link>
                    </div>
                    <nav className="ml-14 space-x-8 font-semibold text-black">
                        <Link className="text-black" to={"/"}>Home</Link>
                        <a className='cursor-pointer text-black' onClick={() => toGetNfts()}>Get NFT</a>
                        <a className='cursor-pointer text-black' onClick={() => toPage("homepage-news")}>News</a>
                        <a className='cursor-pointer text-black' onClick={() => toPage("homepage-contact")}>Contact</a>
                        <a className='cursor-pointer text-black' onClick={() => toMyNfts()}>My NFTs</a>
                    </nav>
                </div>
                <div className="flex items-center space-x-4">
                    <div className='flex space-x-4 mr-3'>
                        <img className="cursor-pointer"
                             onClick={() => window.open("http://twitter.com/KasaiGo_Web3", "_blank")} src="/x-twitter.svg"
                             width={20} height={20} alt={''} />
                        <img className="cursor-pointer"
                             onClick={() => window.open("https://discord.gg/5BpwgwD5Dx", "_blank")} src="/discord.svg"
                             width={20} height={20} alt={''} />
                        <img className="cursor-pointer"
                             onClick={() => window.open("https://instagram.com/kasaigo_web3", "_blank")}
                             src="/instagram.svg" width={20} height={20} alt={''} />
                    </div>
                    {
                        account.jwt ?
                            <div className="flex px-2 bg-cyan-700 text-white py-2 px-4 rounded-lg cursor-pointer"
                                 onClick={copyAddress}>
                                <div className="flex self-center cursor-pointer">
                                    {" "}
                                    <Wallet/>
                                </div>
                                <div className="flex self-center text-white">
                                    {" "}
                                    {account.recikaEthAddress?.substring(0, 8) + "..."}
                                </div>
                            </div>
                            :
                            <button className="bg-cyan-700 text-white py-2 px-4 rounded-lg"
                                    onClick={() => LoginBtnClick()}>
                                {t("login-btn-text")}
                            </button>
                    }

                    {/*<div className="relative">*/}
                    {/*    <div className="img2 text-center" onClick={() => onchangeLanguage()}>*/}
                    {/*        <img src="/language.png" width={30} height={25} alt={''}></img>*/}
                    {/*        <div className="text-xs">{i18n.language?.toUpperCase()}</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </header>
    );
};

export interface SVGProps {
    width?: string;
    height?: string;
    fill?: string;
}

export const Wallet = (props: SVGProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            className="iconify iconify--ic"
            width={props.width ?? "24"}
            height={props.height ?? "24"}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
        >
            <path
                fill={props.fill ?? "currentColor"}
                d="M10 16V8a2 2 0 0 1 2-2h9V5c0-1.1-.9-2-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-1h-9a2 2 0 0 1-2-2zm3-8c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h9V8h-9zm3 5.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"
            ></path>
        </svg>
    );
};
export default Header;
