import { configureStore } from '@reduxjs/toolkit'
import accountSlice from '@/store/account'
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const store = configureStore({
    reducer: {
        account: accountSlice,
    },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// compoment 用
export const useAccountState = () => useAppSelector((state) => state.account);

export default store;