import React from 'react';

const Footer = () => {
  return (
    <footer className="w-4/5 mx-auto py-4 flex justify-between items-center" style={{borderTop:"1px solid"}}>
      <div className="flex justify-between items-center">
        <img className='object-none	' src="/logo.png" alt={''} ></img>
        <span>© 2023 Kasai Go</span>
      </div>

      <div className="flex space-x-6">
          <div className='flex space-x-4'>
              <img className="cursor-pointer"
                   onClick={() => window.open("http://twitter.com/KasaiGo_Web3", "_blank")} src="/x-twitter.svg"
                   width={20} height={20} alt={''} />
              <img className="cursor-pointer"
                   onClick={() => window.open("https://discord.gg/5BpwgwD5Dx", "_blank")} src="/discord.svg"
                   width={20} height={20} alt={''} />
              <img className="cursor-pointer"
                   onClick={() => window.open("https://instagram.com/kasaigo_web3", "_blank")}
                   src="/instagram.svg" width={20} height={20} alt={''} />
          </div>
        <div className="mobile-hidden">|</div>
        <div className="ml-2">
         <span className="cursor-pointer"
             onClick={() => window.open(window.location.href + "特定商取引法に基づく表記.pdf", "_blank")}>特定商取引法に基づく表記</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

