import {Porte, RecikaProvider} from "@recika/web3-provider";
import { RecikaProviderOptions } from "@recika/web3-provider/dist/provider/CoreProvider";

export const DefaultApiUrl = process.env.REACT_APP_BACKEND_URL;
export const domain = process.env.REACT_APP_API_DOMAIN;
export const popModel = process.env.REACT_APP_POPUP_MODEL;


export const dappLink = "https://metamask.app.link/dapp/" + domain + "/";

export const shareUrl = "https://" + domain + "/back/share?t=";

export const NetWorks: { [key: string]: string } = {
    Mainnet: "0x1", // 1
    Ropsten: "0x3", // 3
    Rinkeby: "0x4", // 4
    Goerli: "0x5", // 5
    Kovan: "0x2a", // 42
    Mumbai: "0x13881", // 80001
    Polygon: "0x89", // 137,
};
export const getRecikaProviderConfig = (network: string, onClosed?: CallableFunction): Promise<RecikaProvider> => {
    let conf: RecikaProviderOptions = {
        isMetamask: false,
        expired: 60 * 60 * 24,
        popupLogo: window.location.href + "logo.png" ?? undefined,
        // @ts-ignore
        mode: process.env.REACT_APP_WALLET_MODE,
        sns: ["Email", "Google"],
        usaTerm: true,
        onClosed
    };
    if (network === "Recika") {
        conf["rpcUrl"] = process.env.REACT_APP_RECIKA_RPC_URL;
    } else {
        conf["infura"] = {
            // @ts-ignore
            network: network.toLowerCase(),
            apiKey: process.env.REACT_APP_INFURA_PROJECT_ID || "",
        };
    }

    return Porte.createProvider(conf) as Promise<RecikaProvider>;
};