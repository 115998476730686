import React from "react";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "@/router";

interface ChildProps {
    onUpdate: () => void;
}

function ThanksReg({ onUpdate }: ChildProps) {
    const navigate = useNavigate();
    const handleButtonClick = () => {
        // 子组件通过调用回调函数 onUpdate 来更新父组件的值
        onUpdate();
    };
    const toPage = () => {
        navigate(RoutePaths.NFT)
    }
    return (
        <div className="full-screen-overlay relative bg-45AAC3">
            <div
                className="absolute left-1/4 text-center text-white space-y-10 w-6/12 mod:left-0 mod:px-5 mod:top-20 mod:w-full"
                style={{top: "20%"}}>
                <div className="bg-ycan-600">
                    <div className="text-2xl"> 会員登録ありがとうございます</div>
                    {/*<div className="my-4">*/}
                    {/*    <div> テキストがはいりますテキストがはいりますテキストがはいりますテキストがはいりますテキストがはいりますテキストがはいります</div>*/}
                    {/*    <div> テキストがはいりますテキストがはいりますテキストがはいります</div>*/}
                    {/*</div>*/}


                    <div className="my-2"> Membership NFTを取得しました。</div>
                    <div className="flex justify-center">
                        <div className="w-1/5 text-center mod:w-1/2">
                            <div className="w-full h-64 bg-white rounded flex items-center justify-center">
                                <div className="text-black font-bold">Membership NFT</div>
                            </div>
                        </div>
                    </div>
                    <div className="my-6">
                        <button className="bg-white border-solid rounded text-cyan-600 text-xl p-2 font-bold"
                                onClick={handleButtonClick}>My NFTSへ
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThanksReg;