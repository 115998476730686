import {Get,Post} from "@/utils";

export const getProductList = async (headers: { Auth: any; "content-type": string }): Promise<any> => {
    const res: any = await Get("/product/list", [], headers);
    const req = JSON.parse(res.data);
    if(req.code > 0){
        return null;
    }else{
        return req.data;
    }

};

export const getProductDetail = async (id: number, headers: { Auth: any; "content-type": string }): Promise<any> => {
    const res: any = await Get("/product/" + id, [], headers);
    debugger
    const req = JSON.parse(res.data);
    if(req.code > 0){
        return null;
    }else{
        return req.data;
    }
};
export const postBuyProduct = async (params: any, headers: { Auth: any; "content-type": string }): Promise<any> => {
    const res:any = await Post("/token/buy", {
        body: JSON.stringify(params),
        headers: headers,
    });
    const req = res.data
    if (req.code > 0){
        return null;
    }else {
        return req.data;
    }
};