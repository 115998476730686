
export class Utils {
  public static scrollToDiv = (elementId: string) => {
    const el = document.getElementById(elementId);
    if (el) {
      const top = el.offsetTop;
      window.scrollTo({ top, behavior: "smooth" });
    }
  };

  public static isJSON = (target: string) => {
    try {
      const obj = JSON.parse(target);
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log("[Error] ", e);
      return false;
    }
  };
  public static toMoney = (num: string) => {
    var x = num.toString(),
      result = "";
    while (x.length > 3) {
      result = "," + x.slice(-3) + result;
      x = x.slice(0, x.length - 3);
    }
    if (x) {
      result = x + result;
    }
    return result;
  }
}
