import React, {useEffect, useState} from 'react';
import './App.scss';
import {Outlet, useNavigate} from "react-router-dom";
import Header from "../src/components/Layout/Header";
import MobileHeader from "../src/components/Layout/HeaderForMobile";
import Footer from "../src/components/Layout/Footer";
import MobileFooter from "../src/components/Layout/FooterForMobile"
import 'antd/dist/antd.min.css';
import {AppContext, IAppContext} from './hooks';
import { message, notification, Spin } from "antd";
import store, {useAccountState, useAppDispatch} from "@/store";
import { getRecikaProviderConfig, NetWorks } from "@/constants";
import { Actions } from "@/store/account";
import {loginApi} from "@/services";
import ThanksReg from "@/components/Popup/ThanksReg";
import {RoutePaths} from "@/router";

function App() {
    const account = useAccountState();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [popupFlg, setPopupFlg] = useState<boolean>(false);

    useEffect(() => {
    })

    const [width, setWidth] = React.useState(window.innerWidth);

    const loginFunc = async (routeUrl: string) => {
        const porte = await getRecikaProviderConfig("Recika");

        let popupResult = await porte.login();
        let res = await loginApi(
            {
                corecikaToken: "Bearer " + popupResult?.popup.token,
            },
        );
        if (!res) {
            notification.error({
                message: "Something goes wrong, please try again!",
            });
            return;
        }
        dispatch(
            Actions.login({
                chain: 12377,
                jwt: res?.token,
                email: res?.token,
                address: popupResult?.popup.metamaskEthAddress,
                recikaEthAddress: res?.recikaEthAddress,
                corecikaToken: popupResult?.popup.token,
                firstLoginFlg: res?.loginTimes == 1,
                role: popupResult?.popup.role,
            })
        );
        if (res?.loginTimes == 1) {
            setPopupFlg(true);
            return;
        }
        if (routeUrl && routeUrl !== "") {
            navigate(routeUrl);
        }
    };

    const logoutFunc = (callback?: CallableFunction) => {
        dispatch(Actions.logout)
        callback && callback()
    }
    const breakpoint = 768;
    const isMobile = () => {
        const ua = navigator.userAgent.toLowerCase();
        return (
            ua.indexOf("mobile") > -1 ||
            ua.indexOf("iphone") > -1 ||
            ua.indexOf("android") > -1 ||
            ua.indexOf("iemobile") > -1 ||
            ua.indexOf("windows phone") > -1
        );
    };
    const numberFormat = (n: string | undefined) => {
        if (n === undefined) {
            return "";
        }
        const num: string[] = n.toString().split(".");
        var arr = num[0].split("").reverse();
        var res = [];
        for (var i = 0, len = arr.length; i < len; i++) {
            if (i % 3 === 0 && i !== 0) {
                res.push(",");
            }
            res.push(arr[i]);
        }
        res.reverse();
        let result = '';
        if (num[1]) {
            result = res.join("").concat("." + num[1]);
        } else {
            result = res.join("");
        }
        return result;
    }
    const initialContext: IAppContext = {
        login: loginFunc,
        logout: logoutFunc,
        util: {
            isMobile: isMobile,
            numberFormat: numberFormat,
        },
        width,
        state: {
            account,
            dispatch
        }
    }
    const handleChildUpdate = () => {
      setPopupFlg(false);
        navigate(RoutePaths.NFT);
    }

    return (
        <AppContext.Provider value={initialContext}>
            <Spin spinning={account.loading} size="large">
                <div className="App">
                    <div className="pc-hidden">
                        <MobileHeader/>
                    </div>
                    <div className="mobile-hidden">
                        <Header/>
                    </div>
                    <Outlet/>
                    <div className="pc-hidden">
                        <MobileFooter/>
                    </div>
                    <div className="mobile-hidden">
                        <Footer/>
                    </div>
                </div>
                {popupFlg && <ThanksReg onUpdate={handleChildUpdate} />}
            </Spin>
        </AppContext.Provider>
    );
}

export default App;
