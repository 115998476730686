import React, {useContext, useState} from 'react';
import {RoutePaths} from "@/router";
import {Link, useNavigate} from "react-router-dom";
import {CloseOutlined} from '@ant-design/icons';
import {Utils} from "@/utils";
import {useTranslation} from "react-i18next";
import {LocalLanguage, Wallet} from "@/components/Layout/Header";
import {Actions} from "@/store/account";
import copy from "clipboard-copy";
import {notification} from "antd";
import {useAccountState, useAppDispatch} from "@/store";
import {AppContext} from "@/hooks";

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState<Boolean>(false);
    const navigation = useNavigate();
    const {t, i18n} = useTranslation();
    const account = useAccountState();
    const ctx = useContext(AppContext)
    const dispatch = useAppDispatch();

    const toggleMenu = (flg: Boolean) => {
        const body = document.body;
        const html = document.documentElement;
        setIsMenuOpen(flg);
        if (flg) {
            // 锁定滚动条
            body.style.overflow = 'hidden';
            html.style.overflow = 'hidden';
        } else {
            // 解锁滚动条（如果需要恢复滚动）
            body.style.overflow = 'auto';
            html.style.overflow = 'auto';
        }
    };

    const toPage = (pageName: string) => {
        toggleMenu(false);
        if (window.location.pathname !== "/") {
            //navigate(RoutePaths.HOME, { state: { divId: "news" } });
            navigation(RoutePaths.HOME);
        }
        Utils.scrollToDiv(pageName)
    }
    const changeRouter = (path: string) => {
        toggleMenu(false);
        navigation(path);
    }
    const LoginBtnClick = () => {
        if (account.jwt === null) {
            ctx.login(RoutePaths.HOME);
        } else {
            dispatch(Actions.logout());
            navigation(RoutePaths.HOME);
        }
    };

    const copyAddress = async (e: any) => {
        e.stopPropagation();
        copy(account.recikaEthAddress!).then(() => {
            notification.success({message: t("copySucceed")});
        });
    };
    const onchangeLanguage = () => {
        let language: string = i18n.language == LocalLanguage.JA ? LocalLanguage.EN : LocalLanguage.JA;
        i18n.changeLanguage(language);
    };
    const toGetNfts = async () => {
        if (account.jwt === null) {
            ctx.login(RoutePaths.PRODUCT);
        } else {
            navigation(RoutePaths.PRODUCT);
        }
    }
    return (
        <>
            <div className="p-4 flex justify-between items-center">
                <div className="w-2/6">
                    <img src="/menu.png" alt="menu" className="w-8 h-auto" onClick={() => toggleMenu(true)}/>
                </div>
                <div className="w-2/6">
                    <span onClick={() => changeRouter(RoutePaths.HOME)}>
                      <img src="/logo.png" alt="logo Image" className="w-full h-auto"/>
                    </span>
                </div>
                <div className="w-2/6 flex items-center justify-end space-x-4">

                    {
                        account.jwt ?
                            <div className="flex px-2 bg-cyan-700 text-white py-2 px-4 rounded-lg cursor-pointer"
                                 onClick={copyAddress}>
                                <div className="flex self-center cursor-pointer">
                                    <Wallet/>
                                </div>
                                <div className="flex self-center text-white">
                                    {account.recikaEthAddress ? account.recikaEthAddress?.substring(0, 1) + "..." : "null"}
                                </div>
                            </div>
                            :
                            <button className="bg-cyan-700 text-white rounded-lg p-2 text-xs font-bold"
                                    onClick={() => LoginBtnClick()}>
                                {t("mobile-login-btn-text")}
                                <br/>
                                {t("mobile-reg-btn-text")}
                            </button>
                    }

                    {/*<div className="relative">*/}
                    {/*    <div className="img2 text-center" onClick={() => onchangeLanguage()}>*/}
                    {/*        <img src="/language.png" width={30} height={25} alt={''}></img>*/}
                    {/*        <div className="text-xs">EN</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            {/* 弹出窗口 */}
            {isMenuOpen && (
                <div className="box animate-left-to-right fullscreen-div">
                    <div className="text-right mr-8 mt-8">
                        <div onClick={() => toggleMenu(false)}>
                            <CloseOutlined style={{fontSize: '32px'}}/>
                        </div>

                    </div>
                    <div className="space-y-4 text-black font-bold text-2xl">
                        <div>
                            <span onClick={() => changeRouter(RoutePaths.HOME)}>Home</span>
                        </div>
                        <div>
                            <a className='cursor-pointer text-black' onClick={() => toGetNfts()}>Get NFT</a>
                        </div>
                        <div>
                            <a className='cursor-pointer text-black' onClick={() => toPage("homepage-news")}>News</a>
                        </div>
                        <div>
                            <a className='cursor-pointer text-black'
                               onClick={() => toPage("homepage-contact")}>Contact</a>
                        </div>
                        <div>
                            <span onClick={() => changeRouter(RoutePaths.NFT)}>My NFTs</span>
                        </div>
                    </div>
                    <div className="flex justify-center mt-10">
                        <div className='flex space-x-4 mr-3'>
                            <img className="cursor-pointer"
                                 onClick={() => window.open("http://twitter.com/KasaiGo_Web3", "_blank")}
                                 src="/x-twitter.svg" width={40} height={20} alt={''} />
                            <img className="cursor-pointer"
                                 onClick={() => window.open("https://discord.gg/5BpwgwD5Dx", "_blank")} src="/discord.svg"
                                 width={40} height={20} alt={''} />
                            <img className="cursor-pointer"
                                 onClick={() => window.open("https://instagram.com/kasaigo_web3", "_blank")}
                                 src="/instagram.svg" width={40} height={20} alt={''} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Header;
