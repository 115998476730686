import React, {useEffect, useState} from 'react'
import {useSearchParams} from "react-router-dom";
import {Buffer} from 'buffer';
import axios from "axios";
import clsx from "clsx";

export default function Detail() {
    const [params] = useSearchParams();
    let l = params.getAll("l");
    let f = params.getAll("f");
    const [data, setData] = useState<any>();
    useEffect(() => {
        getData(l.toString());
        console.log(f.toString());
    },[l.toString()])
    const getData = async (l: string) => {
        const decodedUrl = Buffer.from(l, 'base64').toString('utf-8');
        const response: any = await axios.get(decodedUrl);
        console.log(response, "3hYfEdeVRC");
        if (response.status == "200") {
            setData(response.data);
        }
    }

    return (
        <div className="container w-4/5 m-auto p-5 mod:w-full mod:p-0">
            <div className="text-left text-4xl my-5 mod:ml-5">
                Get NFTS
            </div>

            <div className="flex bg-E3F1F5 p-10 mb-10 mod:inline-block mod:p-5">
                <div className="w-2/5 mod:w-full">
                    <div className="h-96 rounded-lg flex items-center justify-center">
                        <div className="flex items-center justify-center h-full w-full">
                            <img src={data?.image} alt={""} className="details-img-css object-contain" />
                        </div>
                    </div>

                </div>
                <div className="w-3/5 mod:w-full">
                    <div className="text-left ml-10 space-y-5 mod:ml-0 mod:mt-5">
                        <div className="text-2xl mod:text-4xl">
                            <span>{data?.name}</span>
                            <span className={clsx("ml-5 text-sm p-1 rounded text-white")}
                                  style={{background: f.toString() == "true" ? "#0e7490" : '#45C3A7'}}>
                               {f.toString() == "true" ?  "Membership NFT " : "Collection NFT "}
                            </span>
                        </div>
                        <div className="text-lg " dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                        {/*<div className="text-lg">*/}
                        {/*    <button className="bg-45AAC3 text-white py-2 px-4 rounded-lg">BUY (MINT)</button>*/}
                        {/*</div>*/}
                        <div className="text-sm">
                            ※会員登録をしてMembership NFTを獲得すると、Collection NFTを購入することができます。
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
