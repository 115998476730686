
interface ChildProps {
    onUpdate: () => void;
}

function CollectionNFT({ onUpdate }: ChildProps) {
    const handleButtonClick = () => {
        // 子组件通过调用回调函数 onUpdate 来更新父组件的值
        onUpdate();
    };
    return (
        <div className="full-screen-overlay relative bg-45C3A7">
            <div className="absolute top-1/4 left-1/4 text-left text-white space-y-10 mod:left-0 mod:mx-5 mod:top-20">
                <div className="text-right flex justify-end">
                    <img className="cursor-pointer" onClick={handleButtonClick} src={"./g-close.png"} width={30}/>
                </div>
                <div className="">
                    <div className="text-2xl mod:mt-48">コレクションNFT</div>
                    <div>
                        コレクションNFTは、加西市で特別な体験をすることができるNFTです。ふるさと納税や抽選で手に入れ、加西市で様々な体験をして、素敵な時間を過ごそう！
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CollectionNFT;
