import React, {useEffect, useState} from 'react'
import CollectionNFT from "@/components/Popup/CollectionNFT";
import MemberShipNFT from "@/components/Popup/MemberShipNFT";
import {RoutePaths} from "@/router";
import {useNavigate} from "react-router-dom";
import {useAccountState} from "@/store";
import axios, {AxiosRequestHeaders} from "axios";
import {getSigner} from "@/utils/web3";
import {Contract} from "ethers";
import ERC721 from "@/contracts/ERC721.json";
import {notification} from "antd";
import {getGlobalInfo} from "@/services";
import {Buffer} from 'buffer';

export default function List() {
    const navigate = useNavigate();
    const account = useAccountState();
    const [data, setData] = useState([]);

    const [popupFlg, setPopupFlg] = useState<boolean>(false);
    const [memberPopFlg, setMemberPopFlg] = useState<boolean>(false);

    const [gl, setGl] = useState<any>();

    const handleChildUpdate = () => {
        setPopupFlg(false);
    };
    const handleMemberUpdate = () => {
        setMemberPopFlg(false);
    };


    const getMetadata = async () => {
        let networkId: number | null = account.chain;
        const globalInfo = await getGlobalInfo({networkId: networkId});
        setGl(globalInfo);
        const token = account?.corecikaToken;
        if (token) {
            // 访问中间层。
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            };
            let data = {
                networkId: networkId,
                addressFilter: [account.recikaEthAddress]
            }

            let corecika_url = process.env.REACT_APP_CORECIKA_URL + `/erc721/list`;
            let tokens = await axios.post(corecika_url, data, {headers})
            if (tokens.status !== 200) {
                console.log("error", tokens)
                return;
            }
            const addressSBT = globalInfo.addressSBT?.toLowerCase();
            const address721 = globalInfo.address721?.toLowerCase();
            const filteredArray = tokens.data.data.filter((item: any) => item.contractAddress?.toLowerCase() == addressSBT || item.contractAddress?.toLowerCase() == address721);
            setData(filteredArray)
        }
    }

    useEffect(() => {
        getMetadata();
    }, [])
    return (
        <div className="container w-4/5 m-auto p-5 mod:w-full mod:p-0">
            <div className="text-left text-4xl my-5 mod:ml-5">
                My NFTS
            </div>
            <div className="p-10 my-5 mod:p-5" style={{background: "#E3F1F5"}}>
                <div className="flex justify-between items-center my-5">
                    <div className="text-left text-3xl mod:text-2xl">
                        Kasai Go NFTs
                    </div>
                    <div>
                        <span className="p-3 text-cyan-600 bg-white font-bold rounded cursor-pointer"
                              onClick={() => setMemberPopFlg(true)}> About Membership NFT</span>
                        <span
                            className="p-3 text-emerald-500 bg-white font-bold rounded cursor-pointer ml-4 mobile-hidden"
                            onClick={() => setPopupFlg(true)}> About Collection NFT</span>
                    </div>
                </div>
                <div className="pc-hidden text-right py-3 my-4">
                    <span className="p-3 text-emerald-500 bg-white font-bold rounded cursor-pointer ml-4"
                          onClick={() => setPopupFlg(true)}> About Collection NFT</span>
                </div>
                <div className="overflow-x-auto max-w-full">
                    <div className="whitespace-nowrap text-left space-x-20 mod:flex mod:flex-wrap mod:space-x-0">
                        {data.map((item, i) => (
                            <NFTImg cData={item} gl={gl}></NFTImg>
                        ))}
                    </div>
                </div>
            </div>

            {popupFlg && <CollectionNFT onUpdate={handleChildUpdate}></CollectionNFT>}

            {memberPopFlg && <MemberShipNFT onUpdate={handleMemberUpdate}></MemberShipNFT>}
        </div>
    )
}

const NFTImg = (props: any) => {
    const navigate = useNavigate();
    const [imgSrc, setImgSrc] = useState<string>();
    const [title, setTitle] = useState<string>();

    const [tokenUrl, setTokenUrl] = useState<string>("");
    const toDetails = (url: string,flg:boolean) => {
        if (url) {
            let base64EncodedUrl = Buffer.from(url).toString('base64');
            navigate(RoutePaths.NFT_DETAIL.concat("?l=" + base64EncodedUrl+ "&&f=" + flg));
        }
    }
    useEffect(() => {
        fetchNftInfo(props);
    }, []);

    const fetchNftInfo = async (d: any) => {
        const signer = await getSigner("Recika");
        if (!signer) return
        const contractAddress = d.cData.contractAddress;
        const tokenNo = d.cData.tokenNo
        const ERC721Contract = new Contract(
            contractAddress,
            ERC721.abi,
            signer
        );

        const tokenUri = await ERC721Contract["tokenURI(uint256)"](tokenNo);
        console.log("[NFT Uri]", tokenUri);
        if (tokenUri) {
            const response = await axios.get(tokenUri);
            setTokenUrl(tokenUri);
            setImgSrc(response.data.image);
            setTitle(response.data.name);
        } else {
            notification.warning({
                message: `Fetch NFT information failed!`,
            });
        }
    }
    return (
        <div className="w-1/5 text-center inline-block mod:w-1/2 mod:mb-4">
            <div
                className="w-full h-80 bg-white rounded-t-lg flex items-center justify-center mod:w-11/12 mod:h-64 cursor-pointer"
                onClick={() => toDetails(tokenUrl,props.cData.contractAddress === props.gl.addressSBT)}>
                <div className="flex items-center justify-center h-full w-full">
                    <img src={imgSrc} alt={title} className="h-5/6 w-5/6 object-contain"/>
                </div>
            </div>
            <div className="text-white text-left p-1 rounded-b-lg mod:w-11/12"
                 style={{background: props.cData.contractAddress === props.gl.addressSBT ? "#0e7490" : '#45C3A7'}}>
                {props.cData.contractAddress === props.gl.addressSBT ? "Membership NFT" : "Collection NFT"}
            </div>
        </div>
    )
}