import { Base64 } from "js-base64";



export class Sessions {
  public static write(key: string, value: any) {
    if (value) {
      value = JSON.stringify(value);
    }
    sessionStorage.setItem(key, value);
  }

  public static read<T>(key: string): T | null {
    const value: string | null = sessionStorage.getItem(key);
    if (value && value !== "undefined" && value !== "null") {
      return JSON.parse(value) as T;
    }
    return null;
  }

  public static remove(key: string) {
    sessionStorage.removeItem(key);
  }

  public static encode(obj: any): string {
    const code = JSON.stringify(obj);
    return Base64.encode(code);
  }

  public static decode<T>(obj: string | null): T | null {
    if (!obj) return null;
    return JSON.parse(Base64.decode(obj)) as T;
  }
}
