import {Get, Post, Sessions} from "@/utils";

export const loginApi = async (params: {
    corecikaToken: string
}): Promise<any> => {
    let headers = {
        'content-type': 'application/json'
    } as any;
    headers.Auth = params.corecikaToken;
    const res:any = await Post("/user/corecikaTokenLogin", {
        // body: JSON.stringify(params),
        headers: headers
    });
    const req = res.data
    if (req.code > 0){
        return null;
    }else {
        return req.data;
    }
};

export const contactApi = async (params: any, headers: { Auth: any; "content-type": string }): Promise<any> => {
    const res:any = await Post("/contact", {
        body: JSON.stringify(params),
        headers: headers,
    });
    const req = res.data
    if (req.code > 0){
        return null;
    }else {
        return req.data;
    }
};

export const getGlobalInfo = async (params: {
    networkId: number | null,
}): Promise<any> => {
    const res:any = await Get("/globalInfo", params);
    const req = JSON.parse(res.data);
    if(req.code > 0){
        return null;
    }else{
        return req.data;
    }
};